<template>
  <div style="text-align: center; margin-top: 200px; font-size: 40px;">
    <div>{{$store.getters.name}}，欢迎登陆</div>
  </div>

</template>

<script>
export default {

}
</script>

<style>
</style>